<template>
  <Modal @on-close="emit('onClose')">
    <Combobox v-bind="{ ...forwarded, ...attrs }" style="margin-top: 64px; width: 512px">
      <template #header>
        <slot name="header"></slot>
      </template>

      <template #selected-option="slotProps">
        <slot name="selected-option" v-bind="slotProps"></slot>
      </template>

      <template #option="slotProps">
        <slot name="option" v-bind="slotProps"></slot>
      </template>

      <template #empty="slotProps">
        <slot name="empty" v-bind="slotProps"></slot>
      </template>

      <template #footer="slotProps">
        <slot name="footer" v-bind="slotProps"></slot>
      </template>
    </Combobox>
  </Modal>
</template>

<script setup lang="ts" generic="T">
import { useForwardPropsEmits } from "radix-vue";
import type { Emits as ModalOverlayEmits } from "~/components/modal/ModalOverlay.vue";
import type { Emits as ComboboxEmits, Props as ComboboxProps } from "~/components/ui/combobox/Combobox.vue";

export type Props<T> = ComboboxProps<T>;
export type Emits<T> = ComboboxEmits<T> & ModalOverlayEmits;

const props = defineProps<Props<T>>();
const emit = defineEmits<Emits<T>>();

const forwarded = useForwardPropsEmits(props, emit);

const attrs = useAttrs();
</script>
